@import "./bootsstrap_partial";



.react-player video {
  position: relative;
  left: 0;
  top: 0;
  transform: none;
}

.react-player {
  height: auto;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
}
.player-wrapper {
  position: relative;
 // padding-top: 40%; Needed?
  object-fit: cover;
}


@include media-breakpoint-up(md) {
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
  }
  .react-player {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

}